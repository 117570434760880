import ReactGA from 'react-ga4';

const IS_BROWSER = typeof window !== 'undefined';

export function init(code) {
    if (IS_BROWSER && !(window as any).GA_INITIALIZED && code) {
        ReactGA.initialize(code);
    }
}

export function pageview() {
    ReactGA.set({ page: window.location.pathname });
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname });
}

export function event(category = '', action = '') {
    if (category && action) {
        ReactGA.event({ category, action });
    }
}
