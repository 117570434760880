export const saveItem = (field: string, value: any) => {
    if (typeof window !== 'undefined') {
        localStorage.setItem(field, value);
    }
};

export const getItem = (field: string) => {
    if (typeof window !== 'undefined') {
        return localStorage.getItem(field);
    }
    return;
};

export const removeItem = (field: string) => {
    if (typeof window !== 'undefined') {
        localStorage.removeItem(field);
    }
};
