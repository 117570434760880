import Link from "next/link";
import styles from './PreviewBox.module.scss';

const PreviewBox = () => {
  return (
    <div className={styles.preview}>
      This page is a preview. {" "}
      <Link
        href="/api/preview-exit/"
        prefetch={false}
        className="color-primary70"
      >
        Exit preview mode
      </Link>
    </div>
  );
};
export default PreviewBox;
