import { GET_FOOTER } from './GetFooterQuery.query';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import { useMemo } from 'react';
import useSWR from 'swr';

export default function useFooter(initialData, variables) {
    const { data, error, isValidating, mutate } = useSWR(
        [GET_FOOTER, useMemo(() => variables, [variables])],
        fetcherStrapi,
        {
            initialData,
            revalidateOnFocus: false,
        }
    );

    let footer = [];
    if (data && !error) footer = data?.footer?.data?.attributes;

    return {
        loading: isValidating,
        footer,
        mutate,
        error,
    };
}
