import cn from 'classnames';
import styles from './Loading.module.scss';

const Loading = ({
  label,
  fullScreen = false,
  extraClass = '',
  style = {},
}) => {
  const elemClass = cn(
    styles.loading,
    fullScreen && styles.fullScreen,
    extraClass,
    {}
  );

  return (
    <div className={elemClass} style={style}>
      <div></div>
      {label && <span className="d-block h4 fw-semi_bold mt-3">{label}</span>}
    </div>
  );
};

export default Loading;
