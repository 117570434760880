import Button from '@components/atoms/Button/Button';
import Heading from '@components/atoms/Heading/Heading';
import Image from 'next/image';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import styles from '@styles/pages/MaintenancePage.module.scss';

function MaintenancePage() {
  return (
    <>
      <div className="container">
        <Image
          width={1303}
          height={691}
          src="/assets/404/shape.png"
          alt="404 image"
          className="position-absolute h-100"
          style={{ objectFit: 'contain' }}
        />
        <div
          className="row pt-6 pb-6 h-100 align-items-center justify-content-center"
          style={{ minHeight: '100vh' }}
        >
          <div className="column col-md-3"></div>
          <div className="column col-md-6 text-center">
            <Heading
              semantic="h1"
              size="h1"
              value={'We&rsquo;ll be back soon!'}
              className={styles['maintenance-page__title']}
            ></Heading>
            <RichText
              tag="p"
              value={`Sorry for the inconvenience but we&rsquo;re performing some
              maintenance at the moment. If you need to you can always 
              <a href="mailto:IndustryLiaison@usaid.gov">contact us</a>,
              otherwise we&rsquo;ll be back online shortly!`}
              className="mt-4"
            ></RichText>
          </div>
          <div className="column col-md-3"></div>
        </div>
      </div>
    </>
  );
}

export default MaintenancePage;
