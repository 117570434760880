const fetch = require('sync-fetch');

let locales = [];
let defaultLocale = 'en';

try {
  if(process.env.NEXT_PUBLIC_STRAPI_API_URL){
    const data = fetch(
      `${process.env.NEXT_PUBLIC_STRAPI_API_URL?.replace(
        'graphql',
        'api'
      )}/i18n/locales`,
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      }
    ).json();
    if (data && data.length > 0) {
      data.map((locale, index) => {
        locales.push(locale.code);
        if (locale.isDefault === true) defaultLocale = locale.code;
      });
    }
  }
} catch (error) {
  locales = ['en'];
  console.log('cannot fetch languages', error);
}

const i18n = {
  locales: locales.length > 0 ? locales : ['en'],
  defaultLocale: defaultLocale,
  pages: {
    '*': ['common'],
  },
};
module.exports = {
  ...i18n,
};
