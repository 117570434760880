// import btoa from 'btoa';
import { GraphQLClient } from 'graphql-request';
// @ts-ignore
import { getItem } from '@utils/LocalStorage';

const client = new GraphQLClient(process.env.NEXT_PUBLIC_API_URL as string);

// const requestHeaders = {
//     Authorization:
//         'Basic ' +
//         btoa(process.env.WP_USERNAME + ':' + process.env.WP_PASSWORD),
// };

export const fetcher = (query, variables) => {
  const token = getItem('token');
  return client.request(query, variables, {
    /*...requestHeaders,*/ token,
  } as HeadersInit);
};
