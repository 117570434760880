import { ActivateAccountOverlay } from '@components/organisms/ActivateAccountOverlay';
import Notification from '@components/common/Notification/Notification';
import { useAuth } from '@providers/AuthProvider/AuthProvider';
import { useMemo } from 'react';
import useNotification from '@hooks/notification';
import useTranslation from 'next-translate/useTranslation';

const ProfileStatusNotification = () => {
  const { t } = useTranslation('common');
  const { me } = useAuth() as { me: any };
  const { status, dismissNotification } = useNotification(
    me?.organization?.submissionStatus
  );
  // TODO: Nelson adicionei a Notification. 
  const statusComponent = useMemo(
    () =>
      status ? (
        <Notification
          text={t(`profile_status.${status.toLowerCase()}`)}
          type={status.toLowerCase()}
          showClose
          onClose={dismissNotification}
        />
      ) : null,
    [status]
  );

  return (
    <>
      {me && !me.emailConfirmed ? (
        <ActivateAccountOverlay email={me?.email} />
      ) : null}
      {statusComponent}
    </>
  );
};

export default ProfileStatusNotification;
