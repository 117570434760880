import Button from '@components/atoms/Button/Button';
import Image from 'next/image';
import Newsletter from '@components/molecules/Newsletter/Newsletter';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import _ from 'lodash';
import styles from './Footer.module.scss';
import useFooter from '@data/strapi/Footer/Query/GetFooter/GetFooterQuery';
import useTranslation from 'next-translate/useTranslation';

const Footer = ({ initialData }) => {
  const { t, lang } = useTranslation('common');

  const foot: any = useFooter(initialData, {
    locale: lang,
  });
  const { footer, error } = foot;

  if (error) return <pre>{error}</pre>;

  return (
    <div className={styles.footer}>
      <div className="container">
        <div className="row align-items-start pt-4">
          <div className="column col-12 col-md-4 mt-4">
            <div className={styles.logo}>
              <a
                href="https://www.usaid.gov/"
                aria-label="Read more about USAID"
                target="_blank"
                rel="noopener noreferrer"
              >
                <Image
                  width={155}
                  height={46}
                  src="/assets/logo/logo.svg"
                  alt="USAID logo"
                />
              </a>
            </div>
            <Newsletter {...footer?.newsletter} className="mt-5" />

            {footer?.disclaimer && (
              <RichText
                value={footer?.disclaimer}
                className={'d-block h6 w-100 mt-4'}
              ></RichText>
            )}

            {footer?.socials && (
              <div className="d-flex mt-4" style={{gap: "8px"}}>
                {footer?.socials.map((item, index) => {
                  return (
                    <a
                      key={`ft-social-${index}`}
                      href={item.url}
                      target={'_blank'}
                      rel="nofollow"
                      aria-label={item.icon}
                      className={styles.socials}
                      style={{
                        backgroundColor:
                          item.color || 'var(--color-primary-70)',
                      }}
                    >
                      <i className={item.icon}></i>
                    </a>
                  );
                })}
              </div>
            )}
          </div>
          <div className="column col-12 col-md-2"></div>
          <div className="column col-12 col-md-6">
            <div className="row">
              {footer?.menu &&
                footer?.menu.map((item, index) => {
                  return (
                    <div
                      key={`footer-link-${index}`}
                      className="column col-6 col-md-4 mt-4 d-flex"
                      style={{ flexFlow: 'column' }}
                    >
                      <span
                        className="d-block texts fw-bold"
                        style={{ color: 'var(--color-primary-100)' }}
                      >
                        {item.label}
                      </span>
                      {item.links &&
                        item.links.map((submenu, index) => {
                          return (
                            <Button
                              key={`ft-link-${index}`}
                              variation="link"
                              label={submenu.label}
                              url={submenu.url}
                              className={'color-gray-70 fw-normal mt-2 me-0'}
                            />
                          );
                        })}
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
      </div>
      <div className={`${styles.footerBottom} mt-7`}>
        <div className="container">
          <div className="row align-items-center pt-2 pb-2">
            <div className="column">
              {footer?.legalLinks &&
                footer?.legalLinks.map((item, index) => {
                  return (
                    <Button
                      key={`ft-legal-${index}`}
                      variation="link"
                      label={item.label}
                      url={item.url}
                      className={'color-white fw-normal me-3'}
                    />
                  );
                })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
