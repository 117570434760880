import { fetcher } from "@lib/fetcher";
import { profilePhotoExists } from "@utils/getProfilePhotoUrl";
/* import { isClientSide } from '@utils/isClientSide';
 */ import { useEffect, useState } from "react";
import useSWR from "swr";

import { GET_ME } from "./GetMeQuery.query";
import { transformMe } from "./GetMeQuery.transform";

export default function useMe() {
  // Create can Load pages state
  const [canLoad, setCanLoad] = useState(false);
  const [hasProfilePhoto, setHasProfilePhoto] = useState(false);
  // Fetch user
  const { data, error, mutate } = useSWR(GET_ME, fetcher, {
		revalidateOnFocus: true,
		refreshInterval: process.env.REFETCH_ME ? +process.env.REFETCH_ME : 60000,
	});

  // Check if first request was made
  useEffect(() => {
    async function checkData() {
      if (data) {
        const photoProfileExists = await profilePhotoExists(
          data?.me?.organization?.id
        );
        setHasProfilePhoto(photoProfileExists);
        setCanLoad(true);
      }
    }
    checkData();
  }, [data]);

  return {
    loading: !data && !error,
    canLoad,
    me: transformMe(data, hasProfilePhoto),
    mutate,
    error,
    loadingMe: false,
  };
}
