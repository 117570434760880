import { v4 as uuid } from 'uuid';

export function getProfilePhotoUrl(id) {
    return `${
        process.env.NEXT_PUBLIC_PROFILE_IMAGE_URL
    }/${id}.png?cached-version${uuid()}`;
}

export async function profilePhotoExists(id) {
    const url = `${process.env.NEXT_PUBLIC_PROFILE_IMAGE_URL}/${id}.png`;
    return new Promise<boolean>((resolve) => {
        const img = new Image();
        img.onload = function () {
            resolve(true);
        };
        img.onerror = function () {
            resolve(false);
        };
        img.src = url;
    });
}
