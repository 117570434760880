import React, { CSSProperties, ReactNode, useEffect, useRef, useState } from "react";

import RichText from "@components/atoms/RichText/RichText";
import classNames from "classnames";
import styles from "./Accordion.module.scss";

const Accordion = ({ className = "", children }) => {
	return <div className={classNames(styles.accordion, className)}>{children}</div>;
};

export default Accordion;


interface IAccordionItem {
	title: string,
	titleNote?: string,
	description?: string,
	initialOpenState?: boolean,
	children: ReactNode | ReactNode[]
}

export const AccordionItem = ({ title, description, titleNote, children, initialOpenState = false }: IAccordionItem) => {
	const [clicked, setClicked] = useState(false);

	useEffect(() => {
		setClicked(initialOpenState);
	}, [initialOpenState])



	const contentEl = useRef<HTMLInputElement>(null);

	const handleToggle = () => {
		setClicked((prev) => !prev);
	};

	return (
		<div className={`${styles.accordionItem} ${clicked ? styles.active : ""}`}>
			<div className={`${styles.title}`} onClick={handleToggle}>
				<div className={`d-flex align-items-center`} style={{ gap: "8px" }}>
					<span className="color-primary-100 texts fw-semi_bold">{title}</span>
					{titleNote && <span className={`${styles.titleNote} h6`}>{titleNote}</span>}
					<i className="icon-down"></i>
				</div>
				{description && <RichText tag="p" value={description} />}
			</div>
			<div
				ref={contentEl}
				className={styles.content}
				style={
					clicked
						? { height: "auto" }
						: // ? { height: contentEl?.current?.scrollHeight }
						{ height: "0px" }
				}
			>
				{children}
			</div>
		</div>
	);
};
