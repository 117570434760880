import { GraphQLClient } from 'graphql-request';

const client = new GraphQLClient(
  process.env.NEXT_PUBLIC_STRAPI_API_URL as string
);

const requestHeaders = {
  // Authorization:
  //     'Bearer ' +
  //     btoa(process.env.STRAPI_USERNAME + ':' + process.env.STRAPI_PASSWORD),
};

export const fetcherStrapi = async(query, variables, preview = false) => {
  //Note: fiz esta alteração aqui para não ter de ir a todos os ficheiros;
  if (!variables || !variables.state) {
    variables = {
      ...variables,
      state: !preview ? 'LIVE' : 'PREVIEW',
    };
  }
  return client.request(query, variables, requestHeaders);
};
