import React, { memo, useState } from "react";

import Heading from "@components/atoms/Heading/Heading";
import { Modal } from "@components/molecules/Modal";
// import { MessageOverlay } from "./MessageOverlay";
import { Props } from "./types";
import { ROUTES } from "@constants/routes";
import { useAuth } from "@providers/AuthProvider/AuthProvider";
import useResendEmailConfirmation from "@data/backoffice/Registration/Mutations/ResendAccountConfirmation/ResendAccountConfirmation";
import { useRouter } from "next/router";
import useTranslation from "next-translate/useTranslation";

//TODO: Nelson confirma se esta ok pf.

const ActivateAccountOverlay = ({ extraClass = "", message, email }: Props) => {
  const { t } = useTranslation('common')
  
  const { logout } = useAuth() as { logout: () => void };
  const router = useRouter();
  const { mutate } = useResendEmailConfirmation();

  const [showNewConfirmationMessage, setShowNewConfirmationMessage] =
    useState(false);
    
  const handleCloseModal = async () => {
    logout();
    //
    router.push(ROUTES.HOMEPAGE);
  };

  const resendEmail = async () => {
    await mutate({ email });
    setShowNewConfirmationMessage(true);
  };
  return (
    <>
      <Modal
        handleOk={handleCloseModal}
        handleCancel={handleCloseModal}
        // extraClass={styles.modal}
        labelOk={t("common.confirm")}
        title={t("account.activate_title")}
      >
        {!showNewConfirmationMessage && (
          <>
            <Heading
              value={t("account.activate_description")}
              semantic={"span"}
              size={"h6"}
              weight={"normal"}
            />
            <Heading
              value={`${t("account.activate_ask", {button: <a href="#" onClick={resendEmail}>{t("account.activate_ask")}</a>})}.`}
              semantic={"span"}
              size={"h6"}
              weight={"normal"}
            />
          </>
        )}
        {showNewConfirmationMessage && (
          <>
            <Heading
              value={t("account.activate_new")}
              semantic={"span"}
              size={"h6"}
              weight={"normal"}
            />
          </>
        )}
      </Modal>
    </>
  );
};

export default memo(ActivateAccountOverlay);
