import { GET_LOCALES } from './GetLocalesQuery.query';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import { useMemo } from 'react';
import useSWR from 'swr';

export default function useLocales(s, variables) {
  const { data, error, isValidating, mutate } = useSWR(
    [GET_LOCALES, useMemo(() => variables, [variables])],
    fetcherStrapi,
    {
      revalidateOnFocus: false,
    }
  );

  let locales = [];

  if (data && !error) locales = data?.i18NLocales?.data;

  return {
    ...locales,
  };
}
