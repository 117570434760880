export const GET_FOOTER = /* GraphQL */ `
  query getFooter($locale: I18NLocaleCode) {
    footer(locale: $locale) {
      data {
        attributes {
          menu {
            label
            url
            links {
              label
              url
              icon
            }
          }
          disclaimer
          newsletter {
            title
            description
          }
          legalLinks {
            label
            url
          }
          socials {
            __typename
            label
            url
            icon
            color
          }
        }
      }
    }
  }
`;
