import { fetcher } from '@lib/fetcher';

import { RESEND_ACCOUNT_CONFIRMATION } from './ResendAccountConfirmation.mutation';
import { transformResendConfirmationEmail } from './ResendAccountConfirmation.transform';
import { Variables } from './types';

export default function useResendEmailConfirmation() {
    function mutate(variables: Variables) {
        if (process.env.NEXT_PUBLIC_READ_ONLY && process.env.NEXT_PUBLIC_READ_ONLY=== "true") {
            window.location.href = process.env.NEXT_PUBLIC_READ_ONLY_REDIRECT || "/404";
         throw new Error ("Maintenance mode is on");
       }
        return fetcher(RESEND_ACCOUNT_CONFIRMATION, variables).then((data) => {
            return transformResendConfirmationEmail(data);
        });
    }

    return { mutate };
}
