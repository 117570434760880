import debug from 'debug';

const log = debug('analytics');
log.log = console.log.bind(console);

export function init(code) {
    log(`Analytics init triggered for ${code}`);
}

export function plugin(name) {
    log(`Analytics require plugin triggered for ${name}`);
}

export function pageview() {
    log(`Pageview triggered for ${window.location.pathname}`);
}

export function event(category = '', action = '') {
    log(`Event for category ${category} and action ${action} triggered`);
}

export function exception(description = '', fatal = false) {
    log(
        `${
            fatal ? 'Fatal exception' : 'Exception'
        } with description ${description}`
    );
}
