export const LOG_IN = /* GraphQL */ `
    mutation login($data: LoginInput) {
        login(data: $data) {
            accessToken
            refreshToken
            user {
                id
                email
                firstName
                lastName
                emailConfirmed
                organization {
                    id
                    legalName
                    email
                    phoneNumber
                    website
                    facebook
                    linkedIn
                    twitter
                    instagram
                    submissionStatus
                    type {
                        code
                        label
                    }
                    status {
                        code
                        label
                    }
                    partnership
                    countryOfOperations {
                        code
                        label
                    }
                    headquarter {
                        id
                        country {
                            code
                            label
                        }
                        city
                    }
                    statement
                    sectors {
                        code
                        label
                    }
                    factors {
                        code
                        label
                    }
                    preEngagementScore {
                        area {
                            code
                        }
                        score
                    }
                }
            }
        }
    }
`;
