export const defaultSearchValues = {
  from: 0,
  locale: "en",
  size: 5,
};

export const setHttps = (link) => {
  if (link.search(/^http[s]?\:\/\//) == -1) {
    link = 'https://' + link;
  }
  return link;
}

export const formatDate = (date: string | null) => {
  if (!date) return '-';
  const localeOptions: Intl.DateTimeFormatOptions = {
      day: '2-digit',
      month: 'long',
      year: 'numeric',
  };
  return new Date(date).toLocaleDateString('en', localeOptions);
};

export const formatCurrency = (value: string | number | null) => {
  if (!value) return '-';
  const currencyOptions: Intl.NumberFormatOptions = {
      style: 'currency',
      currency: 'USD',
  };
  return new Intl
  .NumberFormat('en-EN', currencyOptions)
  .format(Number(value)
  )
};
