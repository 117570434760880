import { HeadingProps } from '@data/strapi/Common/Types';
import React from 'react';
import cn from 'classnames';
import styles from './Heading.module.scss';

export default function Heading({
  id,
  value,
  semantic = 'h3',
  size = 'h3',
  weight = 'semi_bold',
  className,
  style,
}: HeadingProps) {
  const elClass = cn(styles.heading, `fw-${weight}`, size, className);
  if (!value || Object.keys(value).length === 0) return <></>;

  if (typeof value === 'string') {
    return React.createElement(semantic, {
      id: id,
      className: elClass,
      style: style,
      dangerouslySetInnerHTML: {
        __html: value?.replace(
          /"(.*?)"/,
          "<span class='color-primary-70'>$1</span>"
        ),
      },
    });
  } else {
    return React.createElement(
      semantic,
      {
        id: id,
        className: elClass,
        style: style,
      },
      value
    );
  }
}
