import { Messages } from './Messages/general';
import { SEO } from '@data/strapi/Common/Types';
import _ from 'lodash';

export const googleApiKey = process.env.NEXT_PUBLIC_GOOGLE_API_KEY;
export const websiteUrl = process.env.NEXT_PUBLIC_WEBSITE_URL;
export const imageUrl = process.env.NEXT_PUBLIC_IMAGE_URL;

const SITENAME = 'Work With USAID';

export const ROLE_USAID_STAFF = 'USAID_STAFF';

export const createSeo = (seo?: SEO) => {
  const Url =
    seo?.url ||
    (process.browser ? window.location.href : 'www.workwithusaid.com');

  if (seo) {
    const { metaTitle, metaDescription, metaImage, keywords } = seo;

    const seoTitle = metaTitle ? `${metaTitle} | ${SITENAME}` : Messages.TITLE;
    const seoImage = `${
      metaImage?.data?.attributes.url || metaImage || Messages.IMAGE_URL
    }`;

    return (
      <>
        <meta charSet="UTF-8" />
        <title>{seoTitle}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta
          name="description"
          content={metaDescription || Messages.DESCRIPTION}
        />
        <meta name="keywords" content={keywords} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seoTitle} />
        <meta
          property="og:description"
          content={metaDescription || Messages.DESCRIPTION}
        />
        <meta property="og:site_name" content={SITENAME} />
        <meta property="og:url" content={Url} />
        <meta property="og:image" content={seoImage} />
        <meta name="twitter:card" content={'summary_large_image'} />
        <meta name="twitter:title" content={seoTitle} />
        <meta
          name="twitter:description"
          content={metaDescription || Messages.DESCRIPTION}
        />
        <meta name="twitter:site" content={SITENAME} />
        <meta name="twitter:creator" content={SITENAME} />
        <meta name="twitter:image" content={seoImage} />
        <meta name="twitter:url" content={Url} />
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_API_URL} />.
      </>
    );
  } else {
    const seoImage = Messages.IMAGE_URL;

    return (
      <>
        <meta charSet="UTF-8" />
        <title>{Messages.TITLE}</title>
        <link rel="icon" href="/favicon.ico" />
        <meta name="description" content={Messages.DESCRIPTION} />
        <meta name="keywords" content={Messages.KEYWORDS} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={SITENAME} />
        <meta property="og:description" content={Messages.DESCRIPTION} />
        <meta property="og:site_name" content={SITENAME} />
        <meta property="og:url" content={Url} />
        <meta property="og:image" content={seoImage} />
        <meta name="twitter:card" content={'summary'} />
        <meta name="twitter:title" content={SITENAME} />
        <meta name="twitter:description" content={Messages.DESCRIPTION} />
        <meta name="twitter:site" content={SITENAME} />
        <meta name="twitter:creator" content={SITENAME} />
        <meta name="twitter:image" content={seoImage} />
        <meta name="twitter:url" content={Url} />
        <link rel="preconnect" href={process.env.NEXT_PUBLIC_API_URL} />.
      </>
    );
  }
};
export enum FieldType {
  TEXT = 'text',
  MASKED = 'password',
  NUMBER = 'number',
  EMAIL = 'email',
}

export const PreEngagementAreasInfo = {
  programming: {
    slug: 'programming',
    title: 'Programming',
    sidebar: {
      title: 'Programming',
      subtitle: 'Pre-Engagement Assessment',
      description:
        'Assesses organizational general programming, including your current status with USAID (if any), strategic planning, and business development.',
    },
    complete: {
      title:
        'You’ve completed all the questions in the Programming section. Amazing job!',
      buttonText: 'Save and Continue to the Next Section',
    },
  },
  compliance: {
    slug: 'compliance',
    title: 'Compliance',
    sidebar: {
      title: 'Compliance',
      subtitle: 'Pre-Engagement Assessment',
      description:
        'Assesses whether your organization has the systems to detect, deter, and address misconduct while promoting a culture of compliance; and determine whether your organization has the appropriate registrations to accept USAID funding.',
    },
    complete: {
      title:
        'You’ve completed all the questions in the Compliance section. Good for you!',
      buttonText: 'Save and Continue to the Next Section',
    },
  },
  human_resources: {
    slug: 'human-resources',
    title: 'Human Resources',
    sidebar: {
      title: 'Human Resources',
      subtitle: 'Pre-Engagement Assessment',
      description:
        'Assesses whether your organization has a human resources system with clear policies, procedures, and strategies to recruit, retain, and manage a diverse and inclusive workforce.',
    },
    complete: {
      title:
        'You’ve completed all the questions in the Human Resources section. Hurray!',
      buttonText: 'Save and Continue to the Next Section',
    },
  },
  program_management: {
    slug: 'program-management',
    title: 'Program Management',
    sidebar: {
      title: 'Program Management',
      subtitle: 'Pre-Engagement Assessment',
      description:
        'Assesses your organization’s ability to manage a project cycle across the planning, delivering, evaluating, and adapting phases; and covers the program design process, including partnerships, annual reporting, and gender inclusion. ',
    },
    complete: {
      title:
        'You’ve completed all the questions in the Program Management section. Wonderful!',
      buttonText: 'Save and Continue to the Next Section',
    },
  },
  budget_finance: {
    slug: 'budget-and-finance',
    title: 'Budget & Finance',
    sidebar: {
      title: 'Budget and Finance',
      subtitle: 'Pre-Engagement Assessment',
      description:
        'Assesses your organization’s financial practices and the ways that they promote good financial stewardship and prevent the misuse and misappropriation of resources; and examines how your organization assesses financial risk.',
    },
    complete: {
      title:
        'Thank you for completing the assessment! You answered all the questions. Click below to see results, and explore more of this website to continue learning.',
      buttonText: 'Save and See Your Assessment Results',
    },
  },
};

export enum OrganizationProfileStatus {
  ACCEPTED = 'accepted',
  REJECTED = 'rejected',
  PERMANENTLY_REJECTED = 'permanentlyRejected',
  PENDING = 'pending',
}

export enum USAID_ROLES_LIST {
  ADMIN = 'ADMIN',
  COLLABORATOR = 'COLLABORATOR',
  USAID_STAFF = 'USAID_STAFF',
}
