export const GET_LOCALES = /* GraphQL */ `
  query getLocales {
    i18NLocales {
      data {
        attributes {
          name
          code
        }
      }
    }
  }
`;
