import { GET_HEADER } from './GetHeaderQuery.query';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import { useMemo } from 'react';
import useSWR from 'swr';

export default function useHeader(initialData, variables) {
  const { data, error, isValidating, mutate } = useSWR(
    [GET_HEADER, useMemo(() => variables, [variables])],
    fetcherStrapi,
    {
      initialData,
      revalidateOnFocus: false,
    }
  );

  let header = [];
  if (data && !error) header = data?.header?.data?.attributes;

  return {
    loading: isValidating,
    header,
    mutate,
    error,
  };
}
