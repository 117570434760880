export const GET_HEADER = /* GraphQL */ `
  query getHeader($locale: I18NLocaleCode) {
    header(locale: $locale) {
      data {
        attributes {
          menu {
            label
            url
            links {
              label
              url
              icon
            }
          }
        }
      }
    }
  }
`;
