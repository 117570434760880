export const GET_HEADER_CORPORATE = /* GraphQL */ `
  query getHeaderCorporate {
    headerCorporate {
      data {
        attributes {
          menu {
            label
            url
            links {
              label
              url
              icon
            }
          }
        }
      }
    }
  }
`;
