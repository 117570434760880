import React from "react";
import { TextProps } from "@data/strapi/Common/Types";
import cn from "classnames";
import styles from "./RichText.module.scss";

export default function RichText({ tag, value, className }: TextProps) {
  const tagClassName = tag || "div";
  return (
    <div className={cn(styles.richtext, "texts")}>
      {React.createElement(tagClassName, {
        className: className,
        dangerouslySetInnerHTML: { __html: value },
      })}
    </div>
  );
}
