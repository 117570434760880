export const GET_SCHEDULED_MAINTENANCE = /* GraphQL */ `
  query latestScheduledMaintenance {
    maintenanceSchedules(
      sort: "date:DESC"
      pagination: { limit: 1 }
    ) {
      data {
        attributes {
          notification
          date
          alertDate
        }
      }
    }
  }
`;
