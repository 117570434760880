export const GET_COOKIE_BANNER = /* GraphQL */ `
  query getCookieBanner($locale: I18NLocaleCode) {
    cookieBanner(locale: $locale){
      data{
        attributes{
          message{
            value
          }
          accept
          reject
        }
      }
    }
  }
`;
