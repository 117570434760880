import Button from '@components/atoms/Button/Button';
import { ButtonProps } from '@data/strapi/Common/Types';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import cn from 'classnames';
import styles from './SurveyBar.module.scss';

type SurveyBarProps = {
  label?: string  | JSX.Element;
  button?: ButtonProps;
  className?: string;
};
export default function SurveyBar({
  label,
  button,
  className,
}: SurveyBarProps) {
  return (
    <div
      className={cn(
        styles.surveyBar,
        'bg-primary-70 color-white pt-2 pb-2 hide-print',
        className
      )}
    >
      <div className="container">
        <div className="row">
          <div className="column col-12 d-flex align-items-center">
            <div className="flex-grow-1">
              {typeof label === 'string' ? (
                <RichText value={label || ''}></RichText>
              ) : (
                label
              )}
            </div>
            <div className="flex-shrink-1">
              {button && <Button {...button}></Button>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
