import { Props } from './types';
import React from 'react';
import RichText from '@components/atoms/RichText/RichText';
import classNames from 'classnames';
import styles from './Notification.module.scss';

export default function Notification({
  extraClass = '',
  text,
  type,
  showClose = false,
  fixed = true,
  onClose,
} : Props) {
  const elemClass = classNames(
    styles['notification'],
    type && styles[type],
    fixed && styles['sticky'],
    extraClass
  );

  return (
    <div className={elemClass}>
      <div className="container">
        <div className="row">
          <div className="column col-12">
            <div className="d-flex align-items-center">
              <i className="icon-info me-3" />

              <RichText tag='p' value={text} />

              {showClose && (
                <i
                  className="icon-clear ms-3"
                  style={{cursor:"pointer"}}
                  onClick={onClose ? onClose : () => {}}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
