import { DateTime } from "luxon";
import { GET_SCHEDULED_MAINTENANCE } from './ScheduledMaintenanceQuery.query';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import useSWR from 'swr';

interface ScheduledMaintenance {
    id: string;
    notification: string;
    date: string;
    alertDate: string;
}

export default function useScheduledMaintenance() {
    const { data, error, isValidating } = useSWR(
        [GET_SCHEDULED_MAINTENANCE],
        fetcherStrapi,
        {
            revalidateOnFocus: false,
        }
    );

    const { date, alertDate, ...maintenance } =
        (data?.maintenanceSchedules?.data[0]?.attributes as ScheduledMaintenance) || {};


    let dateVal, alertDateVal;


    if (date) {
        const dateTime = date.split(".");
        const alertDateTime = alertDate.split(".");

        dateVal = DateTime.fromISO(dateTime[0]);
        alertDateVal = DateTime.fromISO(alertDateTime[0]);
    }

    return {
        loading: isValidating || (!data && !error),
        data: {
            ...maintenance,
            maintenanceDate: dateVal && dateVal,
            alertDate: alertDateVal && alertDateVal,
        },
        error,
    };
}
