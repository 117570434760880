export const GET_ME = /* GraphQL */ `
  query getMe {
    me {
      id
      email
      firstName
      lastName
      avatar
      jobTitle
      role
      emailConfirmed
      organization {
        id
        legalName
        slug
        email
        countryDialCode {
          code
          countryDialCode
          label
        }
        phoneNumber
        website
        facebook
        linkedIn
        twitter
        instagram
        submissionStatus
        profilePhoto
        type {
          code
          label
        }
        status {
          code
          label
        }
        partnership
        countryOfOperations {
          code
          label
        }
        headquarter {
          id
          country {
            code
            label
          }
          city
        }
        statement
        sectors {
          code
          label
        }
        factors {
          code
          label
        }
        preEngagementStatus
        preEngagementScore {
          area {
            code
          }
          score
        }
        submissionStatus
        isUnpublished
      }
    }
  }
`;
