import Button from '@components/atoms/Button/Button';
import CookieConsent from 'react-cookie-consent';
import RichText from '@components/atoms/RichText/RichText';
import { hasWindow } from '@utils/hasWindow';
import useCookieBanner from '@data/strapi/CookieBanner/GetCookieBannerQuery';
import useTranslation from 'next-translate/useTranslation';

const CookieBanner = ({ initialData }) => {
  const { t, lang } = useTranslation('common');

  const data: any = useCookieBanner(initialData, {
    locale: lang,
  });
  const { cookieBanner, error } = data;

  if (error) return <></>;

  return (
    <>
      {cookieBanner && cookieBanner?.message?.value && hasWindow() && (
        <CookieConsent
          buttonText={cookieBanner?.accept}
          declineButtonText={cookieBanner?.reject}
          cookieName="wusaid_cookie"
          contentClasses="content"
          expires={90}
          enableDeclineButton
          buttonWrapperClasses="button-container"
          ButtonComponent={(x) => {
            return (
              <Button
                variation={x.id === 'rcc-decline-button' ? 'ghost' : 'primary'}
                label={x.children}
                size={'small'}
                {...x}
                style={{}}
              />
            );
          }}
          onDecline={() => {
            const theCookies = document.cookie.split(';');
            for (let i = 0; i < theCookies.length; i++) {
              document.cookie =
                theCookies[i].split('=')[0] +
                '=; path=/; expires=Thu, 01 Jan 1970 00:00:01 GMT;';
            }
          }}
        >
          <RichText value={cookieBanner?.message?.value}></RichText>
        </CookieConsent>
      )}
    </>
  );
};

export default CookieBanner;
