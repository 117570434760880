import { convertArrayData } from '@data/backoffice/Organization/utils';
import { getProfilePhotoUrl } from '@utils/getProfilePhotoUrl';

export const transformLogIn = (data: any): any => {
    if (data?.login) {
        const { user, accessToken } = data.login;
        const { id, email, firstName, lastName, organization } = user;

        return {
            accessToken,
            me: {
                id,
                email,
                firstName,
                lastName,
                organization: organization && {
                    countryOfOperations: convertArrayData(
                        organization.countryOfOperations
                    ),
                    email: organization.email,
                    facebook: organization.facebook,
                    socioEconomic: convertArrayData(organization.factors),
                    hqCity: organization.headquarter?.city?.id,
                    hqCountry: organization.headquarter?.country?.id,
                    id: organization.id,
                    instagram: organization.instagram,
                    fullName: organization.legalName,
                    linkedin: organization.linkedIn,
                    partnershipStatus: organization.status?.code,
                    phoneNumber: organization.phoneNumber,
                    profilePhoto: organization.profilePhoto,
                    sectors: convertArrayData(organization.sectors),
                    statement: organization.statement,
                    twitter: organization.twitter,
                    type: convertArrayData(organization.type),
                    website: organization.website,
                    submissionStatus: organization.submissionStatus,
                    preEngagementScore:
                        organization.preEngagementScore &&
                        organization.preEngagementScore.length
                            ? organization.preEngagementScore
                            : undefined,
                },
            },
        };
    }
    return undefined;
};
