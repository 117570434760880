import { AccordionItem } from '@components/molecules/Accordion/Accordion';
import Button from '@components/atoms/Button/Button';
import React from 'react';
import cn from 'classnames';
import { defaultSearchValues } from '@lib/utils';
import setLanguage from 'next-translate/setLanguage';
import styles from './LanguageSwitcher.module.scss';
import { useRouter } from 'next/router';

const LanguageSwitcher = ({ variant, locales }) => {
  if (!locales) return <></>

  const router = useRouter();

  const excludedLocales = []

  const lang = router.locale;

  let language = ""
  for (let i = 0; i < Object.keys(locales).length; i++) {
    if (locales[i].attributes.code === lang) {
      language = locales[i].attributes.name;
    }
  }

  if (lang && defaultSearchValues.locale !== lang) defaultSearchValues.locale = lang;

  const sortedLocales = Object.values(locales).sort((a: any, b: any) => a?.attributes?.name.localeCompare(b?.attributes?.name));

  return (
    <div
      className={cn(
        styles.switcher,
        'd-inline-block'
      )}
    >
      {variant === "dropdown" ? (
        <div
          className={cn(styles.menuItem, 'd-inline-block')}
        >
          <Button
            variation="icon"
            label={language}
            iconLeft={
              <i
                className={'icon-online'}
              ></i>
            }
            className={'color-white m-0'}
          />
          <div className={cn(styles.subMenu)}>
            <ul className="nav-container">
              {sortedLocales.length > 0 &&
                sortedLocales.map((locale: any, i) => {
                  if (excludedLocales.indexOf(locale.attributes.code as never) >= 0) return <></>

                  return (
                    <li key={`locale-${locale.attributes.code}`} className={styles.menuItem} >
                      <Button
                        variation="link"
                        label={locale.attributes.name}
                        className={cn(
                          lang === locale.attributes.code ? 'color-white' : 'color-primary-30', 
                          'm-0'
                        )}
                        style={{ margin: '0' }}
                        onClick={async () => {
                          defaultSearchValues.locale = locale.attributes.code;
                          await setLanguage(locale.attributes.code);
                        }}
                      ></Button>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      ) : (
        <AccordionItem
          title={language}
        >
          {sortedLocales.length > 0 &&
            sortedLocales.map((locale: any, i) => {
              if (excludedLocales.indexOf(locale.attributes.code as never) >= 0) return <></>

              return (
                <div
                  key={`locale-mobile-${locale.attributes.code}`}
                  className={'d-table'}
                >
                  <Button
                    key={`mobile-menu-${locale.attributes.code}`}
                    variation="icon"
                    label={locale.attributes.name}
                    onClick={async () => {
                      defaultSearchValues.locale = locale.attributes.code;
                      await setLanguage(locale.attributes.code);
                    }}
                    className={'color-primary-100 mt-2'}
                  />
                </div>
              );
            })}
        </AccordionItem>
      )}
    </div>
  );
};

export default LanguageSwitcher;
