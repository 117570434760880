import { hasWindow } from './hasWindow';
import { getItem, removeItem, saveItem } from './LocalStorage';

export function logoutChatbot() {
    if (isChatbotConnected()) {
        setChatbotAttributes();
        saveItem('resetChatbot', true);
        if (hasWindow()) {
            window.BE_API.onSessionReset = function () {
                setChatbotAttributes();
            };
        }
        window.BE_API.resetSession();
    }
}

export function loginChatbot(user) {
    if (isChatbotConnected()) {
        setChatbotAttributes(user);
        saveItem('resetChatbot', true);
        if (hasWindow()) {
            window.BE_API.onSessionReset = function () {
                setChatbotAttributes(user);
            };
        }
        window.BE_API.resetSession();
    }
}

export function loadChatbot(user) {
    if (hasWindow()) {
        window.BE_API = window.BE_API || {};
        window.BE_API.onLoad = function () {
            const shouldReset = getItem('resetChatbot');
            setChatbotAttributes(user);
            if (shouldReset) {
                removeItem('resetChatbot');
                window.BE_API.resetSession();
            }
        };
        window.BE_API.onChatWindowOpen = function () {
            setChatbotAttributes(user);
        };
        window.BE_API.onMessage = function () {
            setChatbotAttributes(user);
        };
        window.BE_API.onSessionReset = function () {
            setChatbotAttributes(user);
        };
    }
}

// Helper functions
function isChatbotConnected() {
    return (
        hasWindow() &&
        window.BE_API?.isInitialized &&
        window.BE_API?.isInitialized()
    );
}

function setChatbotAttributes(user?) {
    if (user) {
        const { email, firstName, lastName, id, organization } = user;
        window.BE_API.setUserAttributes({
            default_email: String(email),
            default_name: `${firstName} ${lastName}`,
            userAppId: String(id),
            DirectoryProfile: String(
                organization?.submissionStatus !== 'INCOMPLETE'
            ),
            PEA: String(!!organization?.preEngagementScore),
        });
    } else {
        window.BE_API.setUserAttributes({
            DirectoryProfile: 'false',
            PEA: 'false',
        });
    }
    window.BE_API.setSessionAttributes({
        website_url: String(window.location.origin),
        isAuthenticated: String(!!user),
    });
}
