/* eslint-disable react-hooks/exhaustive-deps */
import { getItem, removeItem, saveItem } from '@utils/LocalStorage';
import { useEffect, useState } from 'react';

const NOTIFICATION_KEY = 'dismissed_notification';

const mapStatus = (submissionStatus?: string): string | null => {
    const status = submissionStatus?.toUpperCase();
    switch (status) {
        case 'REJECTED':
        case 'PERMANENTLY_REJECTED':
        case 'PENDING':
            return status;
        case 'APPROVED':
            return 'ACCEPTED';
        default:
            return null;
    }
};

export default function useNotification(submissionStatus) {
    const newStatus = mapStatus(submissionStatus);
    const lastStatus = getItem(NOTIFICATION_KEY);
    const [status, setStatus] = useState<null | string>(null);
    useEffect(() => {
        if (submissionStatus) {
            if (!lastStatus) {
                setStatus(newStatus);
            } else if (lastStatus && lastStatus !== newStatus) {
                removeItem(NOTIFICATION_KEY);
                setStatus(newStatus);
            }
        } else {
            setStatus(null);
        }
    }, [newStatus]);

    return {
        status,
        dismissNotification: () => {
            saveItem(NOTIFICATION_KEY, status);
            setStatus(null);
        },
    };
}
