import { GET_HEADER_CORPORATE } from './GetHeaderCorporateQuery.query';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import { useMemo } from 'react';
import useSWR from 'swr';

export default function useHeaderCorporate(initialData, variables) {
    const { data, error, isValidating, mutate } = useSWR(
        [GET_HEADER_CORPORATE, useMemo(() => variables, [variables])],
        fetcherStrapi,
        {
            initialData,
            revalidateOnFocus: false,
        
        }
    );

    let header = [];
    if (data && !error) header = data?.headerCorporate?.data?.attributes;

    return {
        loading: isValidating,
        header,
        mutate,
        error,
    };
}
