import React, { useMemo, useState } from 'react';

import ErrorMessage from '../ErrorMessage/ErrorMessage';
import { InputWrapperProps } from '@data/strapi/Common/Types';
import cn from 'classnames';

export enum FieldType {
  TEXT = 'text',
  MASKED = 'password',
  NUMBER = 'number',
  EMAIL = 'email',
  CHECKBOX = 'checkbox',
}

const InputWrapper = ({
  type = 'text',
  extraClass = '',
  label,
  value,
  name,
  identifier,
  disabled,
  blocked,
  required,
  placeholder,
  iconLeft,
  iconRight,
  accept,
  action,
  onChange,
  fullWidth,
  errorMessage,
  onBlur,
  onKeyDown,
  error,
  errorExtraclass,
  autocomplete,
  minLength,
  maxLength,
  style,
  ...props
}: InputWrapperProps) => {
  const hasValue = useMemo(() => !!value, [value]);
  // const [hasValue, setHasValue] = useState<boolean>(value);
  const [hidden, setHidden] = useState<boolean>(true);

  const handleBlur = (
    e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    e.persist();
    if (required) {
      if (onBlur) {
        onBlur(e);
      }
    }

    return;
  };

  const handleKeyDown = (
    e: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>
  ): void => {
    if (onKeyDown) {
      onKeyDown(e);
    }
    return;
  };

  const isPassword = type === 'password';


  const elemClasses = cn(
    'input-field',
    iconLeft && 'left-icon',
    iconRight && 'right-icon',
    action && 'has-action',
    extraClass
  );
  // styles["text"],
  // [styles[`text--disabled`]]: disabled,
  // [styles[`text--blocked`]]: blocked,
  // [styles[`text--error`]]: error,
  // [styles["text--full-width"]]: fullWidth,
  // [`${extraClass}`]: extraClass,
  // [styles["text--has-value"]]: hasValue,

  return (
    <div className={elemClasses} style={style}>
      {label && (
        <label htmlFor={identifier}>
          {label}
          {required && ' *'}
        </label>
      )}
      <div>
        {iconLeft ? iconLeft : null}
        {type !== 'textarea' ? (
          <input
            id={identifier}
            name={name}
            disabled={disabled}
            value={value || ''}
            placeholder={placeholder}
            onChange={(e) => {
              onChange && onChange(e);
            }}
            onBlur={handleBlur}
            onKeyUp={handleKeyDown}
            type={type}
            //autoComplete={isPassword || !autocomplete ? 'off' : 'on'}
            autoComplete='new-password'
            checked={type === FieldType.CHECKBOX ? value : undefined}
            accept={accept}
            maxLength={maxLength}
          />
        ) : (
          <textarea
            id={identifier}
            name={name}
            disabled={disabled}
            placeholder={placeholder}
            onChange={(e) => {
              onChange && onChange(e);
            }}
            onBlur={handleBlur}
            onKeyUp={handleKeyDown}
            //autoComplete={!autocomplete ? 'off' : 'on'}
            autoComplete='new-password'
            maxLength={maxLength}
          >
            {value || ''}
          </textarea>
        )}
        {iconRight ? iconRight : null}
        {action ? action : null}
      </div>
      {maxLength && maxLength > 0 && (<span className='d-block color-gray-50 h6 text-end mt-2'>{`${value?.length}/${maxLength}`}</span>)}
      {!!error && errorMessage && hasValue && (
        <ErrorMessage
          // extraClass={errorExtraclass}
          errorMessage={errorMessage}
        // error={error}
        />
      )}
    </div>
  );
};

export default InputWrapper;
