import React, { memo } from 'react';

import classNames from 'classnames';

const ErrorMessage = ({
  errorMessage,
  // error,
  // extraClass = "",
  leftIcon = undefined,
  className = '',
}: {
  errorMessage:
    | string
    | React.JSX.Element
    | React.ReactNode
    | React.ReactPortal;
  leftIcon?: any;
  className?: string;
}) => {
  return (
    <span className={classNames("d-flex align-items-center h6 color-error mt-2", className)}>
      {leftIcon ? leftIcon : <i className={'icon-info'}></i>}

      <span className="ps-2">{errorMessage}</span>
    </span>
  );
};

export default memo(ErrorMessage);
