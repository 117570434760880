import { GET_COOKIE_BANNER } from './GetCookieBannerQuery.query';
import { fetcherStrapi } from '@lib/fetcherStrapi';
import { useMemo } from 'react';
import useSWR from 'swr';

export default function useCookieBanner(initialData, variables) {
    const { data, error, isValidating, mutate } = useSWR(
        [GET_COOKIE_BANNER, useMemo(() => variables, [variables])],
        fetcherStrapi,
        {
            initialData,
            revalidateOnFocus: false,
        }
    );

    let cookieBanner = [];
    if (data && !error) cookieBanner = data?.cookieBanner?.data?.attributes;

    return {
        loading: isValidating,
        cookieBanner,
        mutate,
        error,
    };
}
