import { ROLE_USAID_STAFF } from '@constants/utils';
import { convertArrayData } from '@data/backoffice/Organization/utils';
import { getProfilePhotoUrl } from '@utils/getProfilePhotoUrl';

export const transformMe = (data: any, hasProfilePhoto: boolean): any => {
  if (!data?.me) return undefined;
  if (!data.me.organization && data.me.role === ROLE_USAID_STAFF) {
    return {
      ...data.me,
    };
  }

  const {
    organization: { preEngagementScore, ...organization },
    ...me
  } = data.me;

  return {
    ...me,
    organization: {
      ...organization,
      profilePhoto: hasProfilePhoto ? organization.profilePhoto : undefined,
      countryOfOperations: convertArrayData(organization.countryOfOperations),
      socioEconomic: convertArrayData(organization.factors),
      sectors: convertArrayData(organization.sectors),
      type: convertArrayData(organization.type),
      hqCity: organization.headquarter?.city,
      hqCountry: organization.headquarter?.country?.code,
      hqCountryText: organization.headquarter?.country?.label,
      fullName: organization.legalName,
      partnershipStatus: organization.status?.code,
      preEngagementScore: preEngagementScore?.length
        ? preEngagementScore
        : undefined,
    },
  };
};
