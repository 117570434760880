import { DateTime } from "luxon";
import Notification from '../Notification/Notification';
import useScheduledMaintenance from '@data/strapi/ScheduledMaintenance/ScheduledMaintenanceQuery';

const MaintenanceNotification = () => {
  const { loading, data } = useScheduledMaintenance();

  const now = DateTime.now().toMillis()
  const isBetween = now >= data?.alertDate?.ts && now <= data?.maintenanceDate?.ts

  return !loading && isBetween ? (
    <>
      <Notification
        text={parseNotification(data)}
        type={'maintenance'}
        fixed={false}
      />
    </>
  ) : null;
};

function parseNotification(data) {
  let text = data?.notification;
  const match = text?.match(/\{\{.*\}\}/g) || [];
  for (const m of match) {
    const element = data[m.replaceAll(/\{|\}/g, '')];
    if (element) {
      if (!element.invalidReason) {
        const tz = Intl.DateTimeFormat().resolvedOptions().timeZone;
        const date = element.setZone(tz);
        const dateString = `${date.toFormat('DD')} at ${date.toFormat('h:mma')}`;
        text = text.replace(m, `<strong>${dateString}</strong>`);
      } else {
        text = text.replace(m, `<strong>${element}</strong>`);
      }
    }
  }
  return text;
}

export default MaintenanceNotification;
