export const convertArrayToGraphQL = (array: Array<string>) => {
    if (array) {
        return array.map((elem) => ({ code: elem }));
    }

    return null;
};

export const convertArrayFromGraphQL = (
    array: Array<{ code: string; label: string }>
) => {
    if (array) {
        return array.map((elem) => ({ text: elem.label, id: elem.code }));
    }

    return null;
};

export const convertArrayData = (
    array: Array<{ code: string; label: string }>
) => {
    if (array) {
        return array.map((elem) => elem.code);
    }
    return null;
};
