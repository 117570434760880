import { KeyboardEventHandler, useState } from 'react';

import Button from '@components/atoms/Button/Button';
import Heading from '@components/atoms/Heading/Heading';
import classNames from 'classnames';
import cn from 'classnames';
import styles from './HeaderSearch.module.scss';
import { useRouter } from 'next/router';
import useTranslation from 'next-translate/useTranslation';

const HeaderSearch = ({
    isOpen,
    onClose,
}: {
    isOpen: boolean;
    onClose: () => void;
}) => {
    const { t } = useTranslation('common')

    const [searchTerm, setSearchTerm] = useState<string>('');
    const router = useRouter();

    const handleSearch = () => {
        const queryPath = encodeURIComponent(`search`);
        router.push({
            pathname: `/${queryPath}`,
            query: { searchQuery: searchTerm },
        });

        onClose();
        setSearchTerm('');
    };
    
    const handleSubmit: KeyboardEventHandler<HTMLInputElement> = (e) => {
        const key = e.key as string;

        if (key === 'Enter') {
            handleSearch();
            document.documentElement.classList.remove('no-scroll');
        }
    };

    return (
        <div className={cn(styles.search, isOpen && styles.active)}>
            <div className={styles.SearchContainer}>
                <div className="container">
                    <div className={'row justify-content-center'}>
                        <div className="column col-12 col-md-2 col-print-2"></div>
                        <div
                            className={classNames(
                                styles.SearchContainerContent,
                                'column col-12 col-md-8 col-print-8'
                            )}
                        >
                            <div className="d-flex align-items-center color-secondary-450 text-uppercase">
                                <i className="icon-search me-2"></i>
                                <Heading size="h5" value={t("search.title")}></Heading>
                            </div>
                            <Heading
                                className="mb-3 text-center"
                                size="h3"
                                value={t("search.description")}
                            ></Heading>
                            <div className={styles.searchInput}>
                                <input
                                    placeholder={t("search.placeholder")}
                                    onKeyUp={handleSubmit}
                                    onChange={(e) =>
                                        setSearchTerm(e.target.value)
                                    }
                                    name="global-search"
                                    // identifier=""
                                ></input>
                                <Button
                                    variation={'icon'}
                                    iconLeft={<i className="icon-search"></i>}
                                    className='m-0'
                                    onClick={() => handleSearch()}
                                ></Button>
                            </div>
                        </div>
                        <div className="column col-12 col-md-2 col-print-2"></div>
                    </div>
                </div>
            </div>

            <div
                className={styles.searchBackdrop}
                onClick={() => onClose()}
            ></div>
        </div>
    );
};

export default HeaderSearch;
