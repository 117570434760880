import * as EmailValidator from 'email-validator';

import React, { useState } from 'react';

import Button from '@components/atoms/Button/Button';
import InputWrapper from '@components/atoms/Input/InputWrapper/InputWrapper';
import classNames from 'classnames';
import styles from './Newsletter.module.scss';
import useTranslation from 'next-translate/useTranslation';

const Newsletter = ({ title, description, className }) => {
  const { t } = useTranslation('common');
  const [email, setEmail] = useState<string>('');
  const [isEmailValid, setIsEmailValid] = useState<boolean>(
    validateEmail(email)
  );

  function validateEmail(email: string): boolean {
    return EmailValidator.validate(email);
  }

  return (
    <div className={classNames(styles.newsletter, className)}>
      <span className="d-block h4 fw-semi_bold mb-3">{title}</span>
      <form
        id={'GD-snippet-form'}
        action={
          'https://public.govdelivery.com/accounts/USAIDHQ/subscribers/qualify'
        }
        acceptCharset={'UTF-8'}
        method={'post'}
        target="_blank"
      >
        <input name="utf8" type="hidden" value="&#x2713;" />
        <input type="hidden" name="topic_id" id="topic_id" value="USAIDHQ_12" />
        <InputWrapper
          name="email"
          value={email}
          type={'email'}
          identifier="email"
          placeholder={t('newsletter.placeholder')}
          errorMessage={t('newsletter.error')}
          action={
            <Button
              variation="secondary"
              label={t('newsletter.submit')}
              disabled={!isEmailValid}
              className="h6"
              submitButton
              onClick={() => true}
            />
          }
          onChange={(e) => {
            const email = e.target.value;
            setEmail(email);
            setIsEmailValid(validateEmail(email));
          }}
        />
      </form>
    </div>
  );
};

export default Newsletter;
